function games_model_board(data) {
  const background = data.image_one;

  const games_group_one_image = data.image_two;
  const games_group_two_image = data.image_three;
  const games_group_three_image = data.image_four;
  const games_group_four_image = data.image_five;

  const games_group_one_link = data.link_one;
  const games_group_two_link = data.link_two;

  const url_path = "../assets/games_group/";

  const games_board = `
        <div class="game_click flex justify-evenly w-[95%] h-32 rounded-lg m-2 bg-no-repeat bg-center bg-cover" data-link="${games_group_one_link}|${games_group_two_link}" style="background-image: url('${url_path+background}');">

            <div class="w-[24%] h-full bg-transparent flex items-end">

                <div class="w-full h-full flex items-end bg-contain bg-center bg-no-repeat" style="background-image: url('${url_path+games_group_one_image}');" ></div>

            </div>

            <div class="w-[24%] h-full bg-transparent flex items-end">

                <div class="w-full h-full flex items-end bg-contain bg-center bg-no-repeat" style="background-image: url('${url_path+games_group_two_image}');" ></div>

            </div>

            <div class="w-[24%] h-full bg-transparent flex items-end">

                <div class="w-full h-full flex items-end bg-contain bg-center bg-no-repeat" style="background-image: url('${url_path+games_group_three_image}');" ></div>

            </div>

            <div class="w-[24%] h-full bg-transparent flex items-end">

            <div class="w-full h-full flex items-end bg-contain bg-center bg-no-repeat" style="background-image: url('${url_path+games_group_four_image}');" ></div>

        </div>

        </div>
    `;

  return games_board;
}

window.games_model_board = games_model_board;
