import { ajax } from "jquery";

function init_table_layout() {
    $('table.display').DataTable({
        responsive: true,
        scrollX: true,
        "ordering": false,
        "lengthChange": false,
        "bInfo": false,
        "autoWidth": false,
        "paging": false,
        "filter": true,
        "columnDefs": [{
            className: "dt-head-center text-center h-16 lg:h-16 w-24 lg:w-72 bg-white lg:text-base font-semibold text-[10px] text-center",
            "targets": "_all"
        }],
    });
    const tableId = ["#DataTables_Table_0_wrapper"];

    $("#DataTables_Table_0_filter").addClass("flex flex-col lg:flex-row flex-col-reverse lg:flex-row-reverse lg:justify-between my-4 lg:mt-4").find("label").addClass("lg:mt-2");

    for (let index = 0; index < tableId.length; index++) {
        $(tableId[index]).addClass("lg:w-full w-[95%]");
        $(tableId[index]).find("label").addClass("text-white font-semibold");
        $(".pagination").addClass("text-white flex justify-evenly bg-transparent w-full pt-4");
        $(".paginate_button").addClass("w-20 h-10");
        $(".previous").addClass("bg-slate-600 rounded-l-lg flex justify-center items-center");
        $(".active").addClass("bg-blue-500 flex justify-center items-center");
        $(".next").addClass("bg-slate-600 rounded-r-lg flex justify-center items-center");
        $(".dataTables_filter input").addClass("text-black text-start rounded-lg h-8 lg:h-12 ring-0 outline-none px-4");
        $(".dataTables_filter").addClass("text-white flex justify-end items-center my-2");
        $("thead tr").addClass("bg-slate-800");
        // $("tbody tr").addClass("bg-state-800 hover:bg-blue-600");
    }

    $("#DataTables_Table_0").attr("style", "");
    // $("#DataTables_Table_1").attr("style", "");

    $(".dataTables_scrollHeadInner").attr("style", "");
    $(".dataTable").attr("style", "");
    $('table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child').css('border-right-width', '0');
}

window.init_table_layout = init_table_layout

function destroy_table_layout() {
    // Get a reference to the DataTable instance
    var table = $('table.display').DataTable();

    // Destroy the DataTable
    table.destroy();
}

window.destroy_table_layout = destroy_table_layout

// 举例的 写法
// $(".zoom_image" + [i]).on("click", function () {

//     var image = $(this).find("img").attr("src");

//     let image_name = data.bugs_list[i].image_name;
//     let image_url = "../../assets/bugs_image/"+image_name;

//     zoom_image(image_url, "w-96 h-96", "bug_image");

// })

function zoom_image(imageUrl, className, title) {
    new swal({
        title: title,
        content: {
            element: "img",
            attributes: {
                src: imageUrl,
                alt: title,
                class: className,
            },
        },
    });
}

window.zoom_image = zoom_image

function social_media()
{
    const social_media = `
    <div id="facebook_button" class="w-14 h-14"> <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
            <style type="text/css">
                .st0{fill:#FFFFFF;}
                .st1{fill:#3A559F;}
                .st2{fill:#F4F4F4;}
                .st3{fill:#FF0084;}
                .st4{fill:#0063DB;}
                .st5{fill:#00ACED;}
                .st6{fill:#FFEC06;}
                .st7{fill:#FF0000;}
                .st8{fill:#25D366;}
                .st9{fill:#0088FF;}
                .st10{fill:#314358;}
                .st11{fill:#EE6996;}
                .st12{fill:#01AEF3;}
                .st13{fill:#FFFEFF;}
                .st14{fill:#F06A35;}
                .st15{fill:#00ADEF;}
                .st16{fill:#1769FF;}
                .st17{fill:#1AB7EA;}
                .st18{fill:#6001D1;}
                .st19{fill:#E41214;}
                .st20{fill:#05CE78;}
                .st21{fill:#7B519C;}
                .st22{fill:#FF4500;}
                .st23{fill:#00F076;}
                .st24{fill:#FFC900;}
                .st25{fill:#00D6FF;}
                .st26{fill:#FF3A44;}
                .st27{fill:#FF6A36;}
                .st28{fill:#0061FE;}
                .st29{fill:#F7981C;}
                .st30{fill:#EE1B22;}
                .st31{fill:#EF3561;}
                .st32{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
                .st33{fill:#0097D3;}
                .st34{fill:#01308A;}
                .st35{fill:#019CDE;}
                .st36{fill:#FFD049;}
                .st37{fill:#16A05D;}
                .st38{fill:#4486F4;}
                .st39{fill:none;}
                .st40{fill:#34A853;}
                .st41{fill:#4285F4;}
                .st42{fill:#FBBC05;}
                .st43{fill:#EA4335;}
            </style>
            <path class="st1" d="M23,0H9C4,0,0,4,0,9v14c0,5,4,9,9,9h14c5,0,9-4,9-9V9C32,4,28,0,23,0z"/>
            <path class="st0" d="M26.8,15.4C26.6,15.2,26.3,15,26,15h-5v-3.8c0-0.1,0.1-0.2,0.2-0.2H25c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1h-4 c-3.3,0-5,2.7-5,6v3h-4c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h4v12h5V20h3c0.4,0,0.8-0.2,0.9-0.6l2-3C27.1,16.1,27,15.7,26.8,15.4z"/>
            </svg>
    </div>

    <div id="telegram_button" class="w-14 h-14">
        <svg class="w-14 h-14 fill-slate-50" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)"/>
            <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
            <stop stop-color="#37BBFE"/>
            <stop offset="1" stop-color="#007DBB"/>
            </linearGradient>
            </defs>
        </svg>
    </div>

    <div id="instagram_button" class="w-14 h-14">
        <svg class="w-14 h-14" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint0_radial_87_7153)"/>
            <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint1_radial_87_7153)"/>
            <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint2_radial_87_7153)"/>
            <path d="M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z" fill="white"/>
            <defs>
            <radialGradient id="paint0_radial_87_7153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 23) rotate(-55.3758) scale(25.5196)">
            <stop stop-color="#B13589"/>
            <stop offset="0.79309" stop-color="#C62F94"/>
            <stop offset="1" stop-color="#8A3AC8"/>
            </radialGradient>
            <radialGradient id="paint1_radial_87_7153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11 31) rotate(-65.1363) scale(22.5942)">
            <stop stop-color="#E0E8B7"/>
            <stop offset="0.444662" stop-color="#FB8A2E"/>
            <stop offset="0.71474" stop-color="#E2425C"/>
            <stop offset="1" stop-color="#E2425C" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint2_radial_87_7153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)">
            <stop offset="0.156701" stop-color="#406ADC"/>
            <stop offset="0.467799" stop-color="#6A45BE"/>
            <stop offset="1" stop-color="#6A45BE" stop-opacity="0"/>
            </radialGradient>
            </defs>
            </svg>
    </div>

    <div id="tiktok_button" class="w-14 h-14">
        <svg class="w-14 h-14" viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">

            <g clip-rule="evenodd" fill-rule="evenodd">
            
            <path d="M25 0h200c13.808 0 25 11.192 25 25v200c0 13.808-11.192 25-25 25H25c-13.808 0-25-11.192-25-25V25C0 11.192 11.192 0 25 0z" fill="#010101"/>
            
            <path d="M156.98 230c7.607 0 13.774-6.117 13.774-13.662s-6.167-13.663-13.774-13.663h-2.075c7.607 0 13.774 6.118 13.774 13.663S162.512 230 154.905 230z" fill="#ee1d51"/>
            
            <path d="M154.717 202.675h-2.075c-7.607 0-13.775 6.118-13.775 13.663S145.035 230 152.642 230h2.075c-7.608 0-13.775-6.117-13.775-13.662s6.167-13.663 13.775-13.663z" fill="#66c8cf"/>
            
            <ellipse cx="154.811" cy="216.338" fill="#010101" rx="6.699" ry="6.643"/>
            
            <path d="M50 196.5v6.925h8.112v26.388h8.115v-26.201h6.603l2.264-7.112zm66.415 0v6.925h8.112v26.388h8.115v-26.201h6.603l2.264-7.112zm-39.81 3.93c0-2.17 1.771-3.93 3.959-3.93 2.19 0 3.963 1.76 3.963 3.93s-1.772 3.93-3.963 3.93c-2.188-.001-3.959-1.76-3.959-3.93zm0 6.738h7.922v22.645h-7.922zM87.924 196.5v33.313h7.925v-8.608l2.453-2.248L106.037 230h8.49l-11.133-16.095 10-9.733h-9.622l-7.923 7.86V196.5zm85.47 0v33.313h7.926v-8.608l2.452-2.248L191.509 230H200l-11.133-16.095 10-9.733h-9.622l-7.925 7.86V196.5z" fill="#ffffff"/>
            
            <path d="M161.167 81.186c10.944 7.819 24.352 12.42 38.832 12.42V65.755a39.26 39.26 0 0 1-8.155-.853v21.923c-14.479 0-27.885-4.601-38.832-12.42v56.835c0 28.432-23.06 51.479-51.505 51.479-10.613 0-20.478-3.207-28.673-8.707C82.187 183.57 95.23 189.5 109.66 189.5c28.447 0 51.508-23.047 51.508-51.48V81.186zm10.06-28.098c-5.593-6.107-9.265-14-10.06-22.726V26.78h-7.728c1.945 11.09 8.58 20.565 17.788 26.308zm-80.402 99.107a23.445 23.445 0 0 1-4.806-14.256c0-13.004 10.548-23.547 23.561-23.547a23.6 23.6 0 0 1 7.147 1.103V87.022a51.97 51.97 0 0 0-8.152-.469v22.162a23.619 23.619 0 0 0-7.15-1.103c-13.013 0-23.56 10.543-23.56 23.548 0 9.195 5.272 17.157 12.96 21.035z" fill="#ee1d52"/>
            
            <path d="M153.012 74.405c10.947 7.819 24.353 12.42 38.832 12.42V64.902c-8.082-1.72-15.237-5.942-20.617-11.814-9.208-5.743-15.843-15.218-17.788-26.308H133.14v111.239c-.046 12.968-10.576 23.468-23.561 23.468-7.652 0-14.45-3.645-18.755-9.292-7.688-3.878-12.96-11.84-12.96-21.035 0-13.005 10.547-23.548 23.56-23.548 2.493 0 4.896.388 7.15 1.103V86.553c-27.945.577-50.42 23.399-50.42 51.467 0 14.011 5.597 26.713 14.68 35.993 8.195 5.5 18.06 8.707 28.673 8.707 28.445 0 51.505-23.048 51.505-51.479z" fill="#ffffff"/>
            
            <path d="M191.844 64.902v-5.928a38.84 38.84 0 0 1-20.617-5.887 38.948 38.948 0 0 0 20.617 11.815zM153.439 26.78a39.524 39.524 0 0 1-.427-3.198V20h-28.028v111.24c-.045 12.967-10.574 23.467-23.56 23.467-3.813 0-7.412-.904-10.6-2.512 4.305 5.647 11.103 9.292 18.755 9.292 12.984 0 23.515-10.5 23.561-23.468V26.78zm-44.864 59.773v-6.311a51.97 51.97 0 0 0-7.067-.479C73.06 79.763 50 102.811 50 131.24c0 17.824 9.063 33.532 22.835 42.772-9.083-9.28-14.68-21.982-14.68-35.993 0-28.067 22.474-50.889 50.42-51.466z" fill="#69c9d0"/>
            
            <path d="M154.904 230c7.607 0 13.775-6.117 13.775-13.662s-6.168-13.663-13.775-13.663h-.188c-7.607 0-13.774 6.118-13.774 13.663S147.109 230 154.716 230zm-6.792-13.662c0-3.67 3-6.643 6.7-6.643 3.697 0 6.697 2.973 6.697 6.643s-3 6.645-6.697 6.645c-3.7-.001-6.7-2.975-6.7-6.645z" fill="#ffffff"/>
            
            </g>
            
            </svg>
    </div>
    `;

    return social_media;
}

window.social_media = social_media;

function display_footer() {

    //get the current url link if index.html then add scale-125 and bg-gray-600 to the home link 

    var currentUrl = window.location.href;
    var lastPart = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

    var formData = {
        action: "get_footer_button",
    }
    
    let url = '../include/actions';
    let redirect = 'continue';
    let location = '#';
    let repos = Ajax(formData, url, redirect, location);

    repos.then((data) => {

        // console.log(data);

        let href_array = [];

        data.data.forEach(element => {
            href_array.push(element.footer_link);
        });

        // var href_array = ['./index.html', './promotion.html', './winners.html', './agents.html','./games.html'];
        var div_id = ['index', 'promotion', 'winners', 'agents','games'];
        var div_id_footer = ['footer_index', 'footer_promotion', 'footer_winners', 'footer_agents','footer_games'];

        function footer_button(href_array, div_id, div_id_footer, text, image){
            return `
            <a href="${href_array}" target="_blank" class="relative w-[20%] h-full bg-transparent">
                <div id="${div_id}" class="scale-80 w-full h-full flex flex-col justify-end items-center bg-transparent rounded-t-lg pb-4 hover:scale-125 hover:bg-gray-600">
                    <div id="${div_id_footer}" class="bg-center bg-no-repeat bg-contain w-[80%] h-[80%]" style="background-image: url('../assets/footer_button/${image}');"></div>
                    <span class="golden-text">${text}</span>
                </div>
            </a>
            `;
        }

        for (let i = 0; i < href_array.length; i++) {

            $("#footer_section").append(footer_button(href_array[i], div_id[i], div_id_footer[i], data.data[i].footer_text, data.data[i].footer_image));

        }
    })

    $("#footer_section").addClass("lg:w-[390px] mx-auto w-full h-20 fixed bottom-0 flex justify-around items-center text-center bg-black");

    // if(lastPart == "index.html") {
    //     $("#index").addClass("scale-125 bg-gray-600");
    // } else if(lastPart == "promotion.html") {
    //     $("#promotion").addClass("scale-125 bg-gray-600");
    // } else if(lastPart == "winners.html") {
    //     $("#winners").addClass("scale-125 bg-gray-600");
    // } else if(lastPart == "agents.html") {
    //     $("#agents").addClass("scale-125 bg-gray-600");
    // } else if(lastPart == "games.html") {
    //     $("#games").addClass("scale-125 bg-gray-600");
    // }

    
}



display_footer();