function toggleDropdown(triggerClass, dropdownClass) {
  // Bind click event to trigger elements
  $("." + triggerClass).on("click", function () {
    // Toggle open class
    $(this).toggleClass("open");
    // Find corresponding dropdown menu list
    var dropdownMenu = $(this).find("." + dropdownClass);
    // Slide dropdown menu list up or down
    if ($(this).hasClass("open")) {
      dropdownMenu.slideDown();
    } else {
      dropdownMenu.slideUp();
    }
  });
}

window.toggleDropdown = toggleDropdown;

function loadingIcon(id) {
  let loading = `
    <span class="mx-auto">
        <svg width="25" viewBox="-2 -2 42 42" xmlns="http://www.w3.org/2000/svg" stroke="rgb(30, 41, 59)" class="animate-spin text-white w-8 h-8 loading cursor-not-allowed">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="4">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"></circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18"></path>
                </g>
            </g>
        </svg>
    </span>
    `;
  id.html(loading);
}
window.loadingIcon = loadingIcon;

function triggerButtonOnEnterPress(inputFieldId, buttonId) {
    $("#" + inputFieldId).on("keydown", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault(); // Prevent the default action of the Enter key
        $(buttonId).click(); // Trigger the button click event
      }
    });
}
window.triggerEnter = triggerButtonOnEnterPress;

function toggleNotifyPanel(button2trigger, dropdownpanel) {
  $("#"+button2trigger).on("click", function (event) {
    event.preventDefault();
    $("#"+dropdownpanel).slideToggle();
  });
}

window.toggle_slidedown = toggleNotifyPanel;

