import $ from "jquery";
import swal from "sweetalert";

let index = 0;

function type(elementId, text) {
  if (index < text.length) {
    $("#" + elementId).append(text.charAt(index));
    index++;
    setTimeout(function () {
      type(elementId, text);
    }, 100);
  }
}

window.type = type;

function formatTime(time) {
  var date = new Date("1970-01-01 " + time);

  var hours = date.getHours();
  var minutes = date.getMinutes();

  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes ? minutes : "";

  var formattedTime = hours + (minutes ? ":" + minutes : "") + " " + ampm;
  return formattedTime;
}

window.formatTime = formatTime;

function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

window.getUrlParameter = getUrlParameter;

function getDecodedIdParam(paramName) {
  // Get the value of the specified parameter
  const paramValue = new URLSearchParams(window.location.search).get(paramName);

  // Replace space with +
  const preservedPlusSign = paramValue.replace(/ /g, '+');

  // Now decode the string
  const decodedParamValue = decodeURIComponent(preservedPlusSign);

  return decodedParamValue;
}

window.getDecodedIdParam = getDecodedIdParam;

// function copyAndAlert(elementId) {
//     // Copy text to clipboard
//     var $temp = $("<input>");
//     $("body").append($temp);
//     $temp.val($("#" + elementId).val()).select();
//     var successful = document.execCommand("copy");
//     $temp.remove();
//     console.log("successful: ", successful);

//     // Show success message using Sweet Alert if copy was successful
//     if (successful) {
//         new swal({
//             title: "Copied to clipboard!",
//             icon: "success",
//             timer: 1500,
//             showConfirmButton: false
//         });
//     } else {
//         // Show error message if copy failed
//         new swal({
//             title: "Copy failed",
//             icon: "error",
//             timer: 1500,
//             showConfirmButton: false
//         });
//     }
// }

function copyAndAlert(elementId) {
  // Copy text to clipboard
  var text = $("#" + elementId).val();
  navigator.clipboard.writeText(text).then(
    function () {
      // Show success message using Sweet Alert
      new swal({
        title: "Copied to clipboard!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    },
    function () {
      // Show error message if copy failed
      new swal({
        title: "Copy failed",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  );
}

window.copyAndAlert = copyAndAlert;

function copy_direct_in_js(text) {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

window.copy_direct_in_js = copy_direct_in_js;


function disableSubmitButton(button) {
  button.prop("disabled", true);
}

window.disableSubmitButton = disableSubmitButton;

function enableSubmitButton(button) {
  button.prop("disabled", false).html("Submit").removeClass("cursor-pointer");
}

window.enableSubmitButton = enableSubmitButton;

// function checksession() {
//   let url = "../include/actions";
//   let redirect = "continue";
//   let location = "#";
//   let formData = {
//     action: "check_session",
//   };
//   let repos = Ajax(formData, url, redirect, location);
//   repos.then(function (data) {
//     if (!data.success) {
//       window.location.href = "admin_login.html";
//     }
//   });
// }

// window.checksession = checksession;

// if (window.location.href.indexOf("admin_login.html") == -1 && window.location.href.indexOf("withdraw.html") == -1) {
//   checksession();
// }

function initiateSocket() {
  var socket = io.connect('http://localhost:8877');

  socket.on("connect", () => {
      console.log("与服务器连接成功");
      // localStorage.removeItem("chatData");
      // console.log(localStorage);
  });

  socket.on('data', function (data) {
      var newAmount = parseFloat(data.amount).toFixed(2);
      var newAmountString = newAmount.toString();
      var amountElement = document.getElementById('amount');
      amountElement.innerHTML = '';

      for (var i = 0; i < newAmountString.length; i++) {
          var digitContainer = document.createElement('div');
          digitContainer.classList.add('digit-container');

          var digitElement = document.createElement('div');
          digitElement.classList.add('digit');
          digitElement.innerHTML = `<div class="front">${newAmountString[i]}</div><div class="back"></div>`;

          digitContainer.appendChild(digitElement);
          amountElement.appendChild(digitContainer);
      }

      for (var i = 0; i < newAmountString.length; i++) {
          var digitElement = amountElement.getElementsByClassName('digit')[i];
          var frontFace = digitElement.getElementsByClassName('front')[0];
          var backFace = digitElement.getElementsByClassName('back')[0];
          backFace.innerText = newAmountString[i];
          digitElement.classList.add('flip');

          setTimeout(function () {
              digitElement.classList.remove('flip');
              frontFace.innerText = backFace.innerText;
          }, 300);
      }
  });
}

window.initiateSocket = initiateSocket;

// 调用函数
// initiateSocket();
