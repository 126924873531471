function whatsApp_model_board(data)
{
    const background = data.image_four;

    const whatsapp_agent_one_image = data.image_one;
    const whatsapp_agent_one_link = data.link_one;

    const whatsapp_agent_two_image = data.image_two;
    const whatsapp_agent_two_link = data.link_two;

    const whatsapp_agent_three_image = data.image_three;
    const whatsapp_agent_three_link = data.link_three;

    const whatsapp_agent_five_image = data.image_five;

    const url_path = "../assets/whatsapp_group/"

    const whatsapp_button_class = "w-[25%] h-full bg-no-repeat bg-center bg-cover flex items-end";

    const whatsapp_board = `
            <div class="flex flex-col justify-around items-center w-full h-max">

                <div id="whatsapp_bg" class="flex justify-evenly w-[95%] h-32 rounded-lg m-2 bg-cover bg-center bg-no-repeat" style="background-image: url('${url_path+background}');">

                    <div class="${whatsapp_button_class}" style="background-image: url('${url_path+whatsapp_agent_five_image}');"></div>

                    <div class="whatsapp_button_click ${whatsapp_button_class}" data-whatslink="${whatsapp_agent_one_link}" style="background-image: url('${url_path+whatsapp_agent_one_image}');"></div>

                    <div class="whatsapp_button_click ${whatsapp_button_class}" data-whatslink="${whatsapp_agent_two_link}" style="background-image: url('${url_path+whatsapp_agent_two_image}');"></div>

                    <div class="whatsapp_button_click ${whatsapp_button_class}" data-whatslink="${whatsapp_agent_three_link}" style="background-image: url('${url_path+whatsapp_agent_three_image}');"></div>

                </div>

            </div>
    `; 

    return whatsapp_board;
}

window.whatsApp_model_board = whatsApp_model_board;


function promotion_model_board(data)
{

    const background = data.image_four;

    const whatsapp_agent_one_image = data.image_one;
    const whatsapp_agent_one_link = data.link_one;

    const whatsapp_agent_two_image = data.image_two;
    const whatsapp_agent_two_link = data.link_two;

    const whatsapp_agent_three_image = data.image_three;
    const whatsapp_agent_three_link = data.link_three;

    const url_path = "../assets/whatsapp_group/"

    const whatsapp_board = `
            <div class="flex flex-col justify-around items-center w-full h-max">

                <div id="whatsapp_bg" class="flex justify-end w-[95%] h-36 border-4 border-amber-500 rounded-lg m-2 bg-cover bg-center bg-no-repeat" style="background-image: url('${url_path+background}');">

                    <div class="whatsapp_button_click w-20 h-full bg-no-repeat bg-center bg-cover flex items-end" 
                         data-whatslink="${whatsapp_agent_one_link}" 
                         style="background-image: url('${url_path+whatsapp_agent_one_image}');">

                        <div class="w-full h-full flex items-end">
                            <button>
                                <div class="w-10 h-5 bg-contain bg-center bg-no-repeat bg-[url(./assests/whatsapp.webp)]">
                                </div>
                                <p class="w-full text-center text-white">Contact us</p>
                            </button>
                        </div>

                    </div>

                    <div class="whatsapp_button_click w-20 h-full bg-no-repeat bg-center bg-cover flex items-end" 
                         data-whatslink="${whatsapp_agent_two_link}" 
                         style="background-image: url('${url_path+whatsapp_agent_two_image}');">

                        <div class="w-full h-full flex items-end">
                            <button>
                                <div class="w-10 h-5 bg-contain bg-center bg-no-repeat bg-[url(./assests/whatsapp.webp)]">
                                </div>
                                <p class="w-full text-center text-white">Contact us</p>
                            </button>
                        </div>

                    </div>

                    <div class="whatsapp_button_click w-20 h-full bg-no-repeat bg-center bg-cover flex items-end" 
                         data-whatslink="${whatsapp_agent_three_link}" 
                         style="background-image: url('${url_path+whatsapp_agent_three_image}');">

                        <div class="w-full h-full flex items-end">
                            <button>
                                <div class="w-10 h-5 bg-contain bg-center bg-no-repeat bg-[url(./assests/whatsapp.webp)]">
                                </div>
                                <p class="w-full text-center text-white">Contact us</p>
                            </button>
                        </div>

                    </div>

                </div>

            </div>
    `; 

    return whatsapp_board;
}

window.promotion_model_board = promotion_model_board;



